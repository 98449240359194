<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title title-tag="h5">SEO Settings</b-card-title>
    </b-card-header>
    <validation-observer
      v-slot="{ handleSubmit }" ref="VFormSEO"
    >
    <b-form @submit.prevent="handleSubmit(submitSEO)">
      <b-card-body>
        <b-tabs>
          <b-tab title="ID">
            <b-row class="mt-4">
              <b-col md=8>
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.asp_title_id"/>
                  <VValidate 
                    name="Meta Title (ID)" 
                    v-model="row.asp_title_id"
                    :rules="{...validation.asp_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />   
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.asp_description_id"/>
                  <VValidate 
                    name="Meta Description (ID)" 
                    v-model="row.asp_description_id"
                    :rules="{...validation.asp_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  /> 
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Keywords (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" v-model="row.asp_keywords_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="parent.validatorTags"
                    tag-variant="primary" tag-class="text-white"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="EN">
            <b-row class="mt-4">
              <b-col md=8>
                <b-form-group label-for="homeMetaTitleEN">
                  <template #label>
                    Meta Title (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitleEN" placeholder="Meta Title" v-model="row.asp_title_en"/>
                  <VValidate 
                    name="Meta Title (EN)" 
                    v-model="row.asp_title_en"
                    :rules="{...validation.asp_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />   
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaDescEN">
                  <template #label>
                    Meta Description (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea rows="5" id="homeMetaDescEN" placeholder="Meta Description" v-model="row.asp_description_en"/>
                  <VValidate 
                    name="Meta Description (EN)" 
                    v-model="row.asp_description_en"
                    :rules="{...validation.asp_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  /> 
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaTagsEN">
                  <template #label>
                    Meta Keywords (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTagsEN" v-model="row.asp_keywords_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="parent.validatorTags"
                    tag-variant="primary" tag-class="text-white"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="JP">
            <b-row class="mt-4">
              <b-col md=8>
                <b-form-group label-for="homeMetaTitleJP">
                  <template #label>
                    Meta Title (JP)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitleJP" placeholder="Meta Title" v-model="row.asp_title_jp"/>
                  <VValidate 
                    name="Meta Title (JP)" 
                    v-model="row.asp_title_jp"
                    :rules="validation.asp_title_jp" 
                  />   
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaDescJP">
                  <template #label>
                    Meta Description (JP)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea rows="5" id="homeMetaDescJP" placeholder="Meta Description" v-model="row.asp_description_jp"/>
                  <VValidate 
                    name="Meta Description (JP)" 
                    v-model="row.asp_description_jp"
                    :rules="validation.asp_description_jp" 
                  /> 
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-form-group label-for="homeMetaTagsJP">
                  <template #label>
                    Meta Keywords (JP)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTagsJP" v-model="row.asp_keywords_jp" placeholder="Type and press enter ..." remove-on-delete :tag-validator="parent.validatorTags"
                    tag-variant="primary" tag-class="text-white"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card-body>
      <b-card-footer>
        <div class="text-right">
          <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
        </div>
      </b-card-footer>
    </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
  import Gen from '@/libs/Gen.js'

  export default {
    data(){
      return {
        row: {},
        validation: {}
      }
    },
    computed: {
      parent(){
        return this.$parent.$parent.$parent
      }
    },
    mounted(){
      this.apiGet()
    },
    methods: {
      apiGet(){
        Gen.apiRest('/get/' + this.parent.modulePage +  '/seo', {}, 'GET').then(res => {
          this.row = res.data.row
          this.validation = res.data.validation
        })
      },
      submitSEO(){
        this.$refs.VFormSEO.validate().then(success => {
          if(!success) return

          this.parent.loadingOverlay = true

          Gen.apiRest('/do/' + this.parent.modulePage, 
            { data: { type: 'updateSeo', ...this.row } }
          ).then(res => {
            if(res){
              this.parent.loadingOverlay = false
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            }
          }).catch(err => {
            this.parent.loadingOverlay = false
            this.$swal({title: err.response.data.message, icon:'error'})
          })
        })

      },
    }
  }
</script>
