<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Fasilitas Peralatan</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-lini-produksi-fasilitas-peralatan', apiGet)" id="fasilitas_peralatan" value="Y" unchecked-value="N" name="fasilitas_peralatan" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="fasilitasDescOpen"
        @click="closeProductForm">
        Cancel <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showProductUpdate"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormFasilitasDesc"
      tag="div"
      v-if="fasilitasDescOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row class="mb-2">
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                <b-form-input v-model="row.asc_title_id"  />
                <VValidate 
                  name="Title (ID)" 
                  v-model="row.asc_title_id"
                  rules="required|min:3|max:160" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                <b-form-input v-model="row.asc_title_en"  />
                <VValidate 
                  name="Title (EN)" 
                  v-model="row.asc_title_en"
                  rules="required|min:3|max:160" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                <b-form-input v-model="row.asc_title_jp"  />
                <VValidate 
                  name="Title (JP)" 
                  v-model="row.asc_title_jp"
                  rules="required|min:3|max:160" 
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (ID)</label>         
                <b-form-textarea :rows="3" v-model="row.asc_content_id"  />
                <VValidate 
                  name="Description (ID)" 
                  v-model="row.asc_content_id"
                  rules="min:3" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (EN)</label>         
                <b-form-textarea :rows="3" v-model="row.asc_content_en"  />
                <VValidate 
                  name="Description (EN)" 
                  v-model="row.asc_content_en"
                  rules="min:3" 
                />
              </div>
            </b-col>
            <b-col md=4>
              <div class="form-group">
                <label class="control-label">Description (JP)</label>         
                <b-form-textarea :rows="3" v-model="row.asc_content_jp"  />
                <VValidate 
                  name="Description (JP)" 
                  v-model="row.asc_content_jp"
                  rules="min:3" 
                />
              </div>
            </b-col>
          </b-row>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closeProductForm">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row justify-content-center" v-else>
      <div class="col-md-8 text-center">
        <h3>{{row.asc_title_id}}</h3>
        <div class="sect-heading__line"></div>
        <p>{{row.asc_content_id}}</p>
      </div>
    </div>

    <template v-if="!fasilitasDescOpen">
      <hr />  
      <div class="d-flex align-items-center">              
        <b-button variant="info" class="btn-rounded ml-auto" @click="addFasilitasPeralatan">
          Add Fasilitas Peralatan <i class="fas fa-plus ml-2"></i>
        </b-button>
      </div>
      <b-row>
        <div v-for="(value, index) in fasilitasPeralatan" :key="index" :class="'col-md-'+(value.mfp_image_type == 'L' ? 4 : 2)">
          <div class="eq_facilities_wrap ">
            <img :src="parent.uploader(value.mfp_image)" alt="">
            <div class="overlay_eq_facilities">
              <h3>{{value.mfp_title_id}}</h3>
            </div>
            <b-badge v-if="value.mfp_is_active == 'Y'" variant="success">Active</b-badge>
            <b-badge v-else variant="danger">Inactive</b-badge>
            <div class="wrap_fasilitas_action">
              <b-button
                variant="danger"
                size="sm"
                pill
                @click="$emit('delete', value, {type: 'deleteFasilitas', idKey: 'mfp_id', obj: 'Fasilitas'}, apiGet)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Delete'"
              >
                <i class="fas fa-trash" />
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                pill
                @click="updateFasilitas(value)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Update'"
              >
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </div>
      </b-row>
    </template>

    <b-modal id="modalFasilitas" size="xl"  title="Form Fasilitas Peralatan" no-close-on-backdrop>
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormFasilitas"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitFasilitas)">
          <b-card-body>
            <b-row>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Image <span class="text-danger">*</span></label>         
                  <Uploader key="image_square" v-model="dataModal.mfp_image" type="fasilitas_square"/>
                  <VValidate 
                    name="Image" 
                    v-model="dataModal.mfp_image"
                    :rules="validation.mfp_image" 
                  />
                </div>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (ID) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.mfp_title_id"></b-form-input>
                      <VValidate name="Title (ID)" v-model="dataModal.mfp_title_id" :rules="validation.mfp_title_id" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (EN) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.mfp_title_en"></b-form-input>
                      <VValidate name="Title (EN)" v-model="dataModal.mfp_title_en" :rules="validation.mfp_title_en" />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group label-for="year">
                      <label>Title (JP) <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="dataModal.mfp_title_jp"></b-form-input>
                      <VValidate name="Title (JP)" v-model="dataModal.mfp_title_jp" :rules="validation.mfp_title_jp" />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-col md=4>
                      <b-form-group >
                        <label>Status<span class="text-danger mr5">*</span></label>
                        <b-form-radio-group
                          :options="parent.Config.mr.StatusOptions"
                          v-model="dataModal.mfp_is_active"
                        />
                        <VValidate 
                          name="Status" 
                          v-model="dataModal.mfp_is_active" 
                          :rules="validation.mfp_is_active" 
                        />
                      </b-form-group>
                    </b-col>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
          <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
          <b-button type="button" @click="doSubmitFasilitas" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
let _ = global._

export default {
  props:{
    modulePage: String
  },
  data(){
    return {
      fasilitasDescOpen: false,
      row: {},
      validation: {},
      showContent: null,
      fasilitasPeralatan: [],
      dataModal: {},
      imageType: [
        {value: 'L', label: 'Landscape'},
        {value: 'S', label: 'Square'}
      ]
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    changeImageType(){
      this.$set(this.dataModal, 'mfp_image', '')
    },
    addFasilitasPeralatan(){
      this.dataModal = {mfp_is_active: 'Y'}
      this.$bvModal.show('modalFasilitas')
    },
    updateFasilitas(value){
      this.dataModal = _.clone(value)
      this.$bvModal.show('modalFasilitas')
    },
    showProductUpdate(){
      this.fasilitasDescOpen = true
    },
    closeProductForm(){
      this.fasilitasDescOpen = false
    },
    doSubmit(){
      this.$refs.VFormFasilitasDesc.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateFasilitasPeralatanDesc', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.fasilitasDescOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    doSubmitFasilitas(){
      this.$refs.VFormFasilitas.validate().then(success => {
        if(!success) return

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
          { data: { type: this.dataModal.mfp_id ? 'updateFasilitas' : 'addFasilitas', ...this.dataModal, id: this.dataModal.mfp_id } }
        ).then(res => {
          if(res){
            this.$swal({
              title: res.data.message,
              icon: 'success',
            }).then(() => {
              this.apiGet()
              this.$bvModal.hide('modalFasilitas')
            })
          }
        }).catch(err => {
          this.$swal({title: err.response.data.message, icon:'error'})
        })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/fasilitas_peralatan', {}, 'GET').then(res => {
        this.row = res.data.row
        this.showContent = res.data.showContent
        this.fasilitasPeralatan = res.data.data
        this.validation = res.data.validation
      })
    }
  }
}
</script>
<style scoped>
.sect-heading__line {
  display: inline-block;
  width: 180px;
  height: 12px;
  border-radius: 20px;
  position: relative;
}
.sect-heading__line::before {
  content: '';
  position: absolute;
  top: 0;
  height: 60%;
  left: 60px;
  right: 60px;
  border-radius: 10px;
  background-color: #39768e;
}
.eq_facilities_wrap {
    position: relative;
    margin-bottom: 10px;
}
.eq_facilities_wrap img {
    width: 100%;
}
.overlay_eq_facilities {
    position: absolute;
    width: 100%;
    height: 20%;
    padding: 5px;
    background: #334d50;
    background: -webkit-linear-gradient(to top, #334d50, #cbcaa5);
    background: linear-gradient(to top, #252323, #cbcaa500);
    left: 0;
    bottom: 0;
}
.overlay_eq_facilities h3 {
    padding: 10px 0 0 0;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}
.wrap_fasilitas_action {
  position: absolute;
  z-index: 8;
  top: 16px;
  right: 16px;
  display: flex;
}
.wrap_fasilitas_action .btn {
  margin-left: 8px;
}
.eq_facilities_wrap .badge {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 8;
}
</style>