<template>
  <b-card no-body>
    <b-card-header>
      <b-row class="justify-content-between">
        <b-col md=3>
          <b-card-title title-tag="h5">List Machine</b-card-title>
        </b-col>
        <b-col md=3 class="text-right">
          <b-button variant="success" class="m-l-15 btn-rounded" @click="addModalMachine">
            <i class="fa fa-plus-circle mr-2" />
            Add Machine Category
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">              
        <template v-if="showContent">
          <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-machine-line-production', apiGet)" id="machine" value="Y" unchecked-value="N" name="machine" v-model="showContent">Show Section?</b-form-checkbox>
        </template>
        <b-button 
          variant="secondary"
          class="btn-rounded ml-auto"
          v-if="formOpen"
          @click="closeRevenueForm">
          Close <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button
          variant="outline-info"
          class="btn-rounded ml-auto"
          @click="showRevenueUpdate"
          v-else
        >
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>
       <validation-observer
        v-slot="{ handleSubmit }"
        ref="VFormMachineDesc"
        tag="div"
        v-if="formOpen"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitDesc)">
          <b-card class="border mb-0">
            <template #header>
              <h5 class="card-title">Content</h5>
            </template>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_id"  />
                  <VValidate 
                    name="Title (ID)" 
                    v-model="row.asc_title_id"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_en"  />
                  <VValidate 
                    name="Title (EN)" 
                    v-model="row.asc_title_en"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_jp"  />
                  <VValidate 
                    name="Title (JP)" 
                    v-model="row.asc_title_jp"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (ID)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_id"  />
                  <VValidate 
                    name="Description (ID)" 
                    v-model="row.asc_content_id"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (EN)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_en"  />
                  <VValidate 
                    name="Description (EN)" 
                    v-model="row.asc_content_en"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (JP)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_jp"  />
                  <VValidate 
                    name="Description (JP)" 
                    v-model="row.asc_content_jp"
                    rules="min:3" 
                  />
                </div>
              </b-col>
            </b-row>
            <template #footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-3" @click="closeRevenueForm">Cancel</b-button>
                <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
              </div>
            </template>
          </b-card>
        </b-form>
      </validation-observer>
      <div class="row justify-content-center" v-else>
        <div class="col-md-8 text-center">
          <h3>{{row.asc_title_id}}</h3>
          <div class="sect-heading__line"></div>
          <p>{{row.asc_content_id}}</p>
        </div>
      </div>
      <hr/>
      <template v-if="!formOpen">
        <b-row class="gutter-2" v-if="(data_machine||[]).length">
          <b-col md=6 v-for="(value, index) in data_machine" :key="index">
            <b-card no-body>
              <b-card-header class="d-flex justify-content-between">
                <div>
                  <b-card-title title-tag="p">{{ value.mmc_name_id }}</b-card-title>
                  <b-badge variant="success" v-if="value.mmc_is_active == 'Y'">Active</b-badge>
                  <b-badge variant="danger" v-else>Inactive</b-badge>
                </div>
                <div>
                  <b-button
                    v-b-tooltip.hover
                    title="Edit"
                    class="btn-icon mr-2"
                    variant="outline-warning"
                    @click="editMachineCategory(value)"
                    size="sm"
                  >
                    <i class="ti-marker-alt"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    title="Change Status"
                    class="btn-icon mr-2"
                    variant="outline-info"
                    @click="doChangeStatus(value, {idKey: 'mmc_id', statusKey: 'mmc_is_active', type: 'status_machine_category'})"
                    size="sm"
                  >
                    <i class="ti-settings"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    title="Delete"
                    class="btn-icon"
                    variant="outline-danger"
                    size="sm"
                    @click="doDelete(value, {idKey: 'mmc_id', type: 'delete_machine_category', obj: 'Category'})"
                  >
                    <i class="ti-trash"></i>
                  </b-button>
                </div>
              </b-card-header>
              <b-table-simple striped>
                <tr>
                  <th width="60%">Name</th>
                  <th width="10%">Unit</th>
                  <th width="30%">Action</th>
                </tr>
                <tr v-for="(value2, index2) in value.machine" :key="`${index}${index2}`">
                  <td>
                    <p class="m-0">{{value2.mm_name_id}}</p>
                    <b-badge variant="success" v-if="value2.mm_is_active == 'Y'">Active</b-badge>
                    <b-badge variant="danger" v-else>Inactive</b-badge>
                  </td>
                  <td>{{ value2.mm_unit }}</td>
                  <td>
                    <b-button
                      variant="outline-warning"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="editMachine(value, value2)"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Delete'"
                      @click="doDelete(value2, {type: 'delete_machine_list', idKey: 'mm_id', obj: 'Machine'})"
                    >
                      <i class="fas fa-trash" />
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center">
                    <b-button class="btn-rounded" type="button" @click="addMachine(value)" variant="outline-success">Add Machine</b-button>
                  </td>
                </tr>
              </b-table-simple>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <div class="text-center m-auto">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center">There is no data</h4>
          </div>
        </b-row>
      </template>
    </b-card-body>
    <b-modal id="modalMachine" size="lg" title="Form Machine Category" no-clone-on-backdrop>
    <validation-observer ref="VFormMachineCT" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitMachineCT)">
        <b-row>
          <b-col md=4>
            <b-form-group label-for="nameCTID">
              <label>Name (ID) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mmc_name_id" placeholder="e.g. Equipment"></b-form-input>
              <VValidate name="Name (ID)" v-model="dataModal.mmc_name_id" rules="required|min:3|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group label-for="nameCTEN">
              <label>Name (EN) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mmc_name_en" placeholder="e.g. Equipment"></b-form-input>
              <VValidate name="Name (EN)" v-model="dataModal.mmc_name_en" rules="required|min:3|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group label-for="nameCTEN">
              <label>Name (JP) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mmc_name_jp" placeholder="e.g. 設備"></b-form-input>
              <VValidate name="Name (JP)" v-model="dataModal.mmc_name_jp" rules="required|min:2|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=3>
            <b-form-group class="mt-3">
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="parent.Config.mr.StatusOptions"
                v-model="dataModal.mmc_is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModal.mmc_is_active" 
                rules="required" 
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{close}">
      <b-button type="button" @click="close" variant="secondary">Cancel</b-button>
      <b-button type="submit" @click="submitMachineCT" variant="primary">Submit</b-button>
    </template>
  </b-modal>
  <b-modal id="modalMachineList" size="lg" title="Form Machine List" no-clone-on-backdrop>
    <validation-observer ref="VFormMachineList" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitMachineList)">
        <b-row>
          <b-col md=3>
            <p>Category: {{ dataModal.mmc_name_id }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md=4>
            <b-form-group label-for="nameCTID">
              <label>Name (ID) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mm_name_id" placeholder="e.g. Gear Tester Machine"></b-form-input>
              <VValidate name="Name (ID)" v-model="dataModal.mm_name_id" rules="required|min:3|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group label-for="nameCTEN">
              <label>Name (EN) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mm_name_en" placeholder="e.g. Gear Tester Machine"></b-form-input>
              <VValidate name="Name (EN)" v-model="dataModal.mm_name_en" rules="required|min:3|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=4>
            <b-form-group label-for="nameCTJP">
              <label>Name (JP) <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mm_name_jp" placeholder="e.g. ギヤテスタマシン"></b-form-input>
              <VValidate name="Name (JP)" v-model="dataModal.mm_name_jp" rules="required|min:2|max:160" />
            </b-form-group>
          </b-col>
          <b-col md=3>
            <b-form-group label-for="unit">
              <label>Unit <span class="text-danger mr5">*</span></label>
              <b-form-input v-model="dataModal.mm_unit" type="number" min="1"></b-form-input>
              <VValidate name="Unit" v-model="dataModal.mm_unit" rules="required|min_value:1" />
            </b-form-group>
          </b-col>
          <b-col md=3>
            <b-form-group class="mt-3">
              <label>Status<span class="text-danger mr5">*</span></label>
              <b-form-radio-group
                :options="parent.Config.mr.StatusOptions"
                v-model="dataModal.mm_is_active"
              />
              <VValidate 
                name="Status" 
                v-model="dataModal.mm_is_active" 
                rules="required" 
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{close}">
      <b-button type="button" @click="close" variant="secondary">Cancel</b-button>
      <b-button type="submit" @click="submitMachineList" variant="primary">Submit</b-button>
    </template>
  </b-modal>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
export default {
  props: {
    modulePage: String,
  },
  data(){
    return {
      formOpen: false,
      row: {},
      dataModal: {},
      data_machine: [],
      showContent: null
    }
  },
  mounted(){
    this.apiGet()
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    showRevenueUpdate(){
      this.formOpen = true
    },
    closeRevenueForm(){
      this.formOpen = false
    },
    editMachine(category, item){
      this.dataModal = {}
      this.$set(this, 'dataModal', {
        mmc_name_id: category.mmc_name_id,
        ...item
      })
      this.$bvModal.show('modalMachineList')
    },
    addMachine(item){
      this.dataModal = {}
      this.$set(this, 'dataModal', {
        mmc_name_id: item.mmc_name_id,
        mm_is_active: 'Y',
        mm_category: item.mmc_id 
      })
      this.$bvModal.show('modalMachineList')
    },
    doChangeStatus(v, params={}){
      let id = v[params.idKey]
      let changeStatus = v[params.statusKey] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.parent.swalLoading()

          Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type: params.type,id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              v[params.statusKey] = changeStatus

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.parent.doSetAlertForm(err)
            })
        }
      })
    },
    doDelete(v, params={}){

      let id = v[params.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.swalLoading()
          this.openForm = false


          Gen.apiRest("/do/"+this.modulePage,{data:{type:params.type,id:id}}, "POST")
            .then((resp = {})=>{
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              }).then(() => {
                this.apiGet()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.parent.customClassdoSetAlertForm(err)
            })        
        }
      })
    },
    editMachineCategory(item){
      const data = _.clone(item)
      delete data.machine
      this.dataModal = data
      this.$bvModal.show('modalMachine')
    },
    addModalMachine(){
      this.dataModal.mmc_is_active = 'Y'
      this.$bvModal.show('modalMachine')
    },
    submitMachineCT(){
      this.$refs.VFormMachineCT.validate().then(success => {
        if(!success) return

        this.parent.swalLoading()
        
        Gen.apiRest('/do/'+ this.modulePage, { data: {type: this.dataModal.mmc_id ? 'update_machine_category' : 'add_machine_category', ...this.dataModal} }, "POST")
        .then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success'
          }).then(() => {
            this.$bvModal.hide('modalMachine')
            this.apiGet()
          })
        }).catch(err => {
          this.$swal({
            title: err.response.data.message || 'Something Went Wrong!',
            icon: 'error'
          })
        })
        
      })
    },
    submitMachineList(){
      this.$refs.VFormMachineList.validate().then(success => {
        if(!success) return

        this.parent.swalLoading()
        
        Gen.apiRest('/do/'+ this.modulePage, { data: {type: this.dataModal.mm_id ? 'update_machine_list' : 'add_machine_list', ...this.dataModal} }, "POST")
        .then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success'
          }).then(() => {
            this.$bvModal.hide('modalMachineList')
            this.apiGet()
          })
        }).catch(err => {
          this.$swal({
            title: err.response.data.message || 'Something Went Wrong!',
            icon: 'error'
          })
        })
        
      })
    },
    doSubmitDesc(){
      this.$refs.VFormMachineDesc.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateMachineDesc', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.formOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/machine', {}, 'GET').then(res => {
        this.data_machine = res.data.data
        this.showContent = res.data.showContent
        this.row = res.data.description
      })
    }
  }
}
</script>
<style>
  .card-category {
    min-width: 350px;
  }
</style>
