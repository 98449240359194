<template>
  <b-container fluid>
    <bo-page-title />
    <section id="career">
      <b-tabs v-if="isList">
        <b-tab title="Page Settings">
          <HeroSection v-if="moduleRole('hero')" :modulePage="modulePage" />
          <LiniProduksiSection v-if="moduleRole('line_production')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <MachineSection v-if="moduleRole('machine')" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
          <FasilitasPeralatan v-if="moduleRole('fasilitas')" @delete="doDelete(...arguments)" @updateShowSection="updateShowSection(...arguments)" :modulePage="modulePage" />
        </b-tab>
        
        <b-tab title="SEO" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import SEOSection from '@/components/SEOSection.vue'
import Gen from '@/libs/Gen.js'
import HeroSection from '@/components/HeroSection'
import LiniProduksiSection from './LiniProduksiSection.vue' 
import MachineSection from './MachineSection.vue' 
import FasilitasPeralatan from './FasilitasPeralatan.vue' 

export default {
  extends: GlobalVue,
  components: {
    SEOSection,
    HeroSection,
    LiniProduksiSection,
    MachineSection,
    FasilitasPeralatan
  },
  data() {
    return {}
  },
  methods: {
    updateShowSection(value, slug, callback){

      Gen.apiRest('/do/' + this.modulePage, {data: {type: 'updateShowSetting', value, slug}}).then(res => {
        if(res){
          this.$swal({
            title: res.data.message,
            icon: 'success',
          }).then(() => {
            callback()
          })
        }
      }).catch(err => {
        this.$swal({title: err.response.data.message, icon:'error'})
      })
    },
    doDelete(v, params = {}, callback){

      let id = v[params.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.swalLoading()

          Gen.apiRest("/do/"+this.modulePage,{data:{type:params.type,id:id}}, "POST")
            .then((resp = {})=>{
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              }).then(() => {
                callback()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })        
        }
      })
    },
  },
  mounted() {
    this.apiGet() 
  },
  watch:{
    $route(){
      this.apiGet()  
    },
  }
}
</script>